<template>
  <div>
    <b-modal size="lg" ref="import-upload-modal" hide-footer>

      <b-card>


        <h4 class="mb-10 font-weight-bold text-dark">{{ $t('PAGES.IMPORT.FILE') }}</h4>

        <h5 class="mb-8">
          {{ $t('PAGES.IMPORT.FILE_REQUIREMENTS_INFO') }}
        </h5>

        <div class="ml-8">
          <ul>
            <li v-html="$t('PAGES.IMPORT.FILE_REQUIREMENTS_1')"></li>
            <li v-html="$t('PAGES.IMPORT.FILE_REQUIREMENTS_2')"></li>
            <li v-html="$t('PAGES.IMPORT.FILE_REQUIREMENTS_4')"></li>
            <li v-html="$t('PAGES.IMPORT.FILE_REQUIREMENTS_6')"></li>
            <li v-html="$t('PAGES.IMPORT.FILE_REQUIREMENTS_7')"></li>
            <li v-html="$t('PAGES.IMPORT.FILE_REQUIREMENTS_8')"></li>
          </ul>
        </div>

        <h5 class="mt-8 mb-8">{{ $t('PAGES.IMPORT.VALIDATION_100_ROWS') }}</h5>

        <div>

          <b-form-group
            id="input-group-primary_key"
            label-for="input-primary_key"
          >
            <tip-label class="mb-2" for="field-primary_key" :tip="$t('PAGES.IMPORT.TIP_PRIMARY_KEY')" asterix>{{$t('PAGES.IMPORT.PRIMARY_KEY')}}</tip-label>

            <b-form-select
              v-model="primary_key" :options="primary_key_options">
            </b-form-select>
          </b-form-group>

          <b-form-group
            id="input-group-company_id"
            label-for="input-company_id"
          >
            <tip-label class="mb-2" for="field-company" :tip="$t('PAGES.IMPORT.TIP_PARENT_COMPANY')" asterix>{{$t('PAGES.IMPORT.PARENT_COMPANY')}}</tip-label>

            <b-form-select
              v-model="company_id" :options="company_options" :disabled="true">
            </b-form-select>
          </b-form-group>

          <input hidden type="file" id="file" ref="file" v-on:change="handle_file_upload()" accept=".xlsx" />

          <b-button :disabled="primary_key === null" type="button" variant="success" @click="select_file()"
            >{{ $t('PAGES.IMPORT.UPLOAD_FILE') }}</b-button
          >
        </div>
        <div v-if="file_error" class="mt-4">
          <b-alert show variant="danger"
            >{{ $t('PAGES.IMPORT.INVALID_FILE') }}</b-alert
          >
        </div>

      </b-card>

    </b-modal>


  </div>

</template>

<script>
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import axios from 'axios';
import { mapGetters } from 'vuex';
import TipLabel from '@/view/components/TipLabel.vue';

export default {
  name: 'import-edit-upload-file',
  props: ['type'], /// 'member' or 'company'
  emits: ['on_continue', 'on_excel_file_read'],
  mixins: [ toasts ],
  components: {
    TipLabel
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods', 'companies']),
    period_options() {
      return this.periods.map(item => ({ value: item.id, text: item.name }));
    },
    primary_key_options() {
      return [
        { value: 'CNUM', text: this.$t('COMPANY.CNUM') },
        { value: 'COMPANY_ID', text: this.$t('COMPANY.COMPANY_ID') }
      ]
    },
    company_options() {
      return this.companies.map(item => ({ value: item.id, text: item.name }));
    },
    company_id() {
      return this.currentCompanyId;
    }
  },
  data() {
    return {
      file: null,
      primary_key: null,
      period_id: null
    };
  },
  mounted() {

  },
  watch: {

  },
  methods: {

    show() {
      this.$refs['import-upload-modal'].show();
    },

    hide() {
      this.$refs['import-upload-modal'].hide();
    },


    handle_file_upload() {
      this.file = this.$refs.file.files[0];

      if (this.file.size > (50000000)) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FILES.MUST_BE_LESS'));
        return;
      }

      this.upload_file();
    },
    select_file() {
      this.$refs.file.click();
    },
    upload_file() {
      const loader = this.$loading.show();

      let formData = new FormData();
      formData.append('file', this.file);

      formData.append('file_source', 'files');
      formData.append('file_type', 'IMPORT_COMPANIES');
      formData.append('company_id', this.currentCompanyId);
      formData.append('period_id', this.currentPeriodId);
      formData.append('primary_key', this.primary_key);

      axios
        .post('/fileupload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(res => {
          loader & loader.hide();
          //this.toastr('success', this.$t('COMMON.OK'), 'Filen laddades upp');

          this.file_id = res.data.fileobjs[0].file_id;

          this.$emit('on_continue', this.primary_key, this.file_id);
        })
        .catch(err => {
          loader & loader.hide();
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.IMPORT.UNABLE_UPLOAD'));
        });
    },

    /// asks backend to parse the file headers and give back a suggested
    /// mapping of columns
    read_column_def_from_file(file_id) {
      const loader = this.$loading.show();

      axios
        .get(`/import/read_column_defs/company/${file_id}`)
        .then(res => {
          if (res.status === 200) {

            this.requirements = res.data.requirements;
            this.type_def = res.data.type_def;
            this.column_def = res.data.column_def;
            this.mappings = res.data.mappings;

            this.$emit('on_excel_file_read', this.type_def, this.mappings);

            loader & loader.hide();
          }
          else if (res.status === 409) {
            loader & loader.hide();

            this.toastr('danger', this.$t('COMMON.ERROR'), res.data.message, 10000);
          }
        })
        .catch(err => {
          loader & loader.hide();

          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.IMPORT.FILE_READ_ERROR'));
        });
    },


  }
};
</script>
